import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  type: "",
  typeUser: {},
  profiles: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    UserSignUp: (state, action) => {
      state.user = action.payload;
    },
    UserLogOut: (state) => {
      state.user = initialState.user;
      state.type = initialState.type;
      state.typeUser = initialState.typeUser;
      state.profiles = initialState.profiles;
    },
    UserChangeUserType: (state, action) => {
      state.type = action.payload;
    },
    UserTypeAllData: (state, action) => {
      state.typeUser = action.payload;
    },
    adminUidData: (state, action) => {
      state.user["schoolUid"] = action.payload;
    },
    UserMultipleProfiles: (state, action) => {
      state.profiles = action.payload;
    },
    UserImageHandler: (state, action) => {
      state.user = { ...state.user, image: action.payload };
    },
  },
});

export default usersSlice.reducer;
export const {
  UserSignUp,
  UserLogOut,
  UserChangeUserType,
  UserTypeAllData,
  adminUidData,
  UserMultipleProfiles,
  UserImageHandler,
} = usersSlice.actions;
