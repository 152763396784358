export const LocalTokenName = process.env.REACT_APP_TOKEN_STORAGE_NAME;
export var MainToken = {
  main: "",
  rem: false,
};

export const UpdateToken = (a) => {
  MainToken = a;
};

export const StoreLocal = (a, v) => {
  const token = JSON.stringify(a);
  if (v) {
    localStorage.setItem(LocalTokenName, token);
  } else {
    sessionStorage.setItem(LocalTokenName, token);
  }
};

export const ExportLocalData = () => {
  const token =
    localStorage.getItem(LocalTokenName) ||
    sessionStorage.getItem(LocalTokenName);
  if (token === null || token === undefined) return undefined;
  var newToken = JSON.parse(token);
  UpdateToken(newToken);
  return newToken;
};

export const DeleteLocal = () => {
  localStorage.removeItem(LocalTokenName);
  sessionStorage.removeItem(LocalTokenName);
};
