import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideBar: true,
  page: "",
  parentPage: "",
  states: "all",
  academicSession: {},
  paymentReminder: { showReminder: false, block: false },
};

const compsSlice = createSlice({
  name: "comps",
  initialState,
  reducers: {
    ChangeSideBar: (state) => {
      state.sideBar = !state.sideBar;
    },
    ChangeCurrentPage: (state, action) => {
      state.page = action.payload;
    },
    ChangeParentPage: (state, action) => {
      state.parentPage = action.payload;
    },
    ChangeStates: (state, action) => {
      state.states = action.payload;
    },
    ChangeToInit: (state) => {
      state.sideBar = initialState.sideBar;
      state.page = initialState.page;
      state.parentPage = initialState.parentPage;
      state.states = initialState.states;
      state.academicSession = initialState.academicSession;
      state.paymentReminder = initialState.paymentReminder;
    },
    ChangeAcademicSession: (state, action) => {
      state.academicSession = action.payload;
    },
    ChangePaymentReminder: (state, action) => {
      state.paymentReminder = action.payload;
    },
  },
});

export default compsSlice.reducer;
export const {
  ChangeSideBar,
  ChangeCurrentPage,
  ChangeParentPage,
  ChangeStates,
  ChangeToInit,
  ChangeAcademicSession,
  ChangePaymentReminder,
} = compsSlice.actions;
