import { configureStore } from "@reduxjs/toolkit";
import usersReducers from "./Users/UsersSlice";
import compsReducers from "./Comps/CompsSlice";
import logger from "redux-logger";
import { apiSlice } from "./Api/ApiSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

export const LocalStoreName = process.env.REACT_APP_REDUX_STORAGE_NAME;

function saveToLocalStorage(state) {
  try {
    const temp = { ...state };
    delete temp.api;
    const serialState = JSON.stringify(temp);
    localStorage.setItem(LocalStoreName, serialState);
  } catch (e) {
    console.warn(e);
  }
}
function loadFromLocalStorage() {
  try {
    const serialState = localStorage.getItem(LocalStoreName);
    if (serialState === null) return undefined;
    return JSON.parse(serialState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

let middleware = [apiSlice.middleware];
if (process.env.REACT_APP_REDUX_LOGGER === "true") {
  middleware.push(logger);
}

const Store = configureStore({
  reducer: {
    users: usersReducers,
    comps: compsReducers,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
  preloadedState: loadFromLocalStorage(),
});

Store.subscribe(() => saveToLocalStorage(Store.getState()));

setupListeners(Store.dispatch);

export default Store;
