import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Logo from "../../Assets/Login/logo-fav.png";
import { Grid } from "@mui/material";

function SuspensePage() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "93vh",
        position: "relative",
      }}
    >
      <Grid
        component="img"
        src={Logo}
        loading="lazy"
        sx={{
          width: "70px",
          position: "absolute",
        }}
      />
      <CircularProgress size="80px" thickness={1.8}></CircularProgress>
    </Box>
  );
}

export default SuspensePage;
