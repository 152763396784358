import axios from "axios";
import ToastHandler from "./ToastHandler";
import { MainApiUrl } from "../Imports/MainApiUrl";
import { ImageResponse } from "./ImageResponseHandler";
import { TrimAllBodyData } from "./ConvertFormData";

let showErrorCode =
  process.env.REACT_APP_SHOW_ERROR_CODE === "true" ? true : false;

const ApiCallHandler = (ApiData) => {
  if (ApiData.image) return ImageResponse(ApiData);
  var config = {
    method: ApiData.method,
    url: `${MainApiUrl}${ApiData.endPoint}`,
    headers: {
      "Content-Type": ApiData?.contentType || "application/json",
      Authorization: `Bearer ${ApiData.auth}`,
      "Academic-Session": ApiData?.academicSession || "",
    },
    data: TrimAllBodyData(ApiData.data),
    params: ApiData.params,
    onUploadProgress: ({ loaded, total }) => {
      ApiData?.upPercent &&
        ApiData.upPercent(Math.floor((loaded * 100) / total));
    },
    onDownloadProgress: ({ loaded, total }) => {
      ApiData?.doPercent &&
        ApiData.doPercent(Math.floor((loaded * 100) / total));
    },
  };

  return axios(config)
    .then((response) => {
      return { ...response.data, status_code: response.status };
    })
    .catch((error) => {
      if (error.response && error?.code !== "ERR_NETWORK") {
        return {
          ...error?.response?.data,
          status_code: error?.response?.status,
        };
      } else if (error?.code === "ERR_NETWORK") {
        return { message: error?.message, status_code: error?.code };
      } else if (error.response) {
        return { ...error.response };
      } else {
        return {
          ...error?.response,
          message: "uncaught error",
          status_code: 499,
        };
      }
    });
};

export const ApiResponseHandler = async (ApiData) => {
  return ApiCallHandler(ApiData)
    .then(async (response) => {
      var MainData = {};
      if (response.status_code === 200 || response.status_code === 201) {
        if (ApiData.msz) {
          ToastHandler("sus", response?.message);
        }
        MainData = {
          data: response,
          status: true,
          res: true,
        };
        return MainData;
      } else if (response.status_code === 400) {
        MainData = {
          status: false,
          res: false,
          data: response,
        };
        ToastHandler("warn", response?.message);
        return MainData;
      } else if (response.status_code === 401 || response.status_code === 403) {
        ToastHandler("warn", `${response?.message}`);
        return {
          status: false,
          res: false,
          out: true,
          data: response,
        };
      } else if (response.status_code === 499) {
        ToastHandler(
          "warn",
          `${showErrorCode ? response.status_code + " " : ""}${
            response?.message
          }`
        );
        return {
          status: false,
          res: false,
          data: response,
        };
      } else {
        MainData = {
          status: false,
          res: false,
          data: response,
        };
        if (!(ApiData.image && response.status_code === 406))
          ToastHandler(
            "dan",
            `${showErrorCode ? response.status_code + " " : ""}${
              response?.message
            }`
          );
        return MainData;
      }
    })
    .catch((error) => {
      ToastHandler("dan", error?.response?.message || "API Error");
      return {
        status: false,
        res: false,
      };
    });
};
