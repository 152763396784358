import { createApi } from "@reduxjs/toolkit/query/react";
import { MainToken } from "../../Functions/MainToken";
import { ApiResponseHandler } from "../../Functions/ResponseHandler";
import { UserLogOut } from "../Users/UsersSlice";

const AuthTags = ["Auth-User", "Auth-Student"];
const SuperTags = ["Super-Schools", "superEditList"];
const StudentTags = ["student_leave", "student_otp"];
const SetupTags = [
  "Admin-Setup-Class",
  "Admin-Setup-Section",
  "Admin-Setup-ClassSection",
  "Admin-Setup-Houses",
  "Admin-Setup-Banks",
  "Admin-Setup-Branches",
  "Admin-Setup-Session",
  "Admin-Setup-Group",
  "Admin-Setup-Setting",
  "Admin-Setup-Hostel-Block",
  "Admin-Setup-Hostel-Room",
  "Admin-Setup-Hostel-RoomType",
  "Admission-Circle",
  "Admission-Bus-Routes",
  "Admission-Stops",
  "Admission-Route-Stops",
  "Documents",
  "Setup-Subject",
  "Setup-Leave-Remark",
  "Setup-Subject-Map",
  "Setup-Class-Section-Subject",
];
const WebManagerTags = [
  "AdminWebCategory",
  "AdminTemplate",
  "AdminTemplatePage",
  "AdminTemplateSection",
  "AdminTemplateStatic",
  "AdminWebPage",
  "AdminMenuManager",
  "AdminSchoolTemplate",
];
const resultTags = [
  "Admin-result-term",
  "Admin-Result-Grade",
  "Admin-result-marksheet",
  "Admin-result-student-sub",
  "Admin-result-attachment",
  "Admin-result-Board-RollNo",
  "Admin-result-des-indicator",
  "Admin-result-term-subject-setting",
  "Admin-result-remark-list",
  "Admin-result-marks-entry-student",
  "Admin-result-remark-Field",
  "Admin-result-freeze-term",
  "Admin-result-marksheet-final-setting",
];

const WebCms = [
  "WebCms-News",
  "WebCms-Category-News",
  "WebCms-Events",
  "WebCms-Category-Events",
  "WebCms-Circular",
  "WebCms-Category-Circular",
  "WebCms-Photo-Gallery",
  "WebCms-Category-Photo-Gallery",
  "WebCms-Video-Gallery",
  "WebCms-Category-Video-Gallery",
  "WebCms-Slider",
  "WebCms-Category-Slider",
  "WebCms-Publication",
  "WebCms-Category-Publication",
  "WebCms-Media-Press",
  "WebCms-Category-Media-Press",
  "WebCms-Bulletin-Board",
  "WebCms-Category-Bulletin-Board",
  "WebCms-Thought-Of-The-Day",
  "WebCms-Award-And-Achievement",
];
const libraryTags = [
  "Library-Settings-DDC",
  "Library-Settings-Streams",
  "Library-Settings-Sub-Streams",
  "Library-Settings-Supplier",
  "Library-Settings-Head",
  "Library-Settings-IssueGroup",
  "Library-Settings-Almirah",
  "Library-Settings-Periodical",
];
const paymentTags = ["Admin-payment-integration-status"];

const manageMentTag = [
  "management-school-dropdown",
  "managementSchools-Super",
  "GetAllManagement",
  "management-Users",
];

const axiosBaseQuery =
  ({ baseUrl }) =>
  async (payload, api) => {
    let state = api.getState();
    const response = await ApiResponseHandler({
      method: payload.method,
      endPoint: payload.url,
      data: payload.body,
      auth: MainToken.main,
      msz: payload.msz,
      image: payload.image,
      contentType: payload.contentType,
      upPercent: payload.upPercent,
      doPercent: payload.doPercent,
      academicSession: !payload.skipAcademicSession
        ? state.comps?.academicSession?.value || ""
        : "",
    });
    if (response.res) {
      return { data: response.data };
    } else if (response.out) {
      api.dispatch(UserLogOut());
      return { error: response.data };
    } else {
      return { error: response.data };
    }
  };

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({ baseUrl: "" }),
  tagTypes: [
    ...AuthTags,
    ...SuperTags,
    ...StudentTags,
    ...SetupTags,
    ...resultTags,
    ...WebManagerTags,
    ...WebCms,
    ...libraryTags,
    ...paymentTags,
    "Admin-ManageUser",
    "Admin-Fee-Group",
    "Admin-Fee-Installment",
    "Admin-Fee-Component",
    "Admin-Fee-FineName",
    "Admin-Fee-Structure",
    "Admin-Fee-Cheque",
    "Admin-Fee-Concession-Name",
    "Admin-Fee-Concession-Structure",
    "Admin-Fee-DailyFeeGet",
    "Admin-Fee-DailyFeeTrans",
    "Admin-StudentFee-Structure",
    "Admin-Misc-StudentWise",
    "Admin-Fee-Refund",
    "Admin-Fee-RefundTrans",
    "Admission-Student-info",
    "Admin-ManageUser-details",
    "Admin-ManageUser-Rights",
    "Admission-SLC",
    "Admission-Strength",
    "Admin-SMS-Template",
    "Attendance-Class-Listing",
    "Attendance-Student-Leave",
    "Attendance-Student-Listing",
    "Leave-Listing",
    "Admin-Student-SMS",
    "Admin-SMS-Log",
    "Admin-Group-SMS",
    "installment",
    "Admission-section-from-class",
    "Admission-stope-from-circle-and-route",
    "Admission-Student-info-delete-form",
    "student_modified",
    "Attandance-for-SLC",
    "userLog",
    "Academic-CMS",
    "Annual-broadcast",
    "WebCms-News",
    "WebCms-Events",
    "WebCms-Category-Events",
    "WebCms-Circular",
    "WebCms-Category-Circular",
    "WebCms-Gallery",
    "WebCms-Category-Gallery",
    "WebCms-Video-Gallery",
    "WebCms-Category-Video-Gallery",
    "WebCms-Publication",
    "WebCms-Category-Publication",
    "WebCms-Media-Press",
    "WebCms-Category-Media-Press",
    "WebCms-Bulletin-Board",
    "WebCms-Category-Bulletin-Board",
    "WebCms-Thought-Of-The-Day",
    "WebCms-Award-And-Achievement",
    "AcademicCMS-user-list",
    "AcademicCMS-Homework-Report",
    "AcademicCMS-Homework-List",
    "Admission-Student-image-get",
    "Res-Pool-Folder-Create",
    "Training-Calender",
    "Feedback_Forms",
    ...manageMentTag,
  ],
  keepUnusedDataFor: 300,
  refetchOnMountOrArgChange: 10,
  refetchOnFocus: false,
  refetchOnReconnect: true,
  endpoints: (builder) => ({}),
});
